@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

@font-face {
  font-family: 'FontAwesome';
  src: url('../static/font-awesome-4.7.0/fonts/FontAwesome.otf') format('opentype');
}

@font-face {
  font-family: 'Maax Bold';
  src: url('../static/font-maax/Sans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Maax Regular';
  src: url('../static/font-maax/Sans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Open Sans";
  src: url('../static/font-open-sans/OpenSans-Regular.ttf')
}

@font-face {
  font-family: 'Raisonne AN DemiBold';
  src: url('../static/font-raisonne/raisonne_demibold_an.eot');
  src: url('../static/font-raisonne/raisonne_demibold_an.eot?#iefix') format('embedded-opentype'),
  url('../static/font-raisonne/raisonne_demibold_an.woff') format('woff'),
  url('../static/font-raisonne/raisonne_demibold_an.ttf') format('truetype'),
  url('../static/font-raisonne/raisonne_demibold_an.svg#RaisonneANDemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('../static/font-dinpro/DINPro-Regular.ttf');
}

@font-face {
  font-family: 'DINPro Bold';
  src: url('../static/font-dinpro/DINPro-Bold.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('../static/font-inter/Inter.ttf');
}

button:focus, input:focus, select:focus {
  outline: 0;
}

button.link {
  border: 0;
  background: transparent;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html, body, select, input {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;

  /*   Enable panning and pinch zoom gestures, but disable additional non-standard gestures such as double-tap to zoom. Disabling double-tap to zoom removes the need for browsers to delay the generation of click events when the user taps the screen. */
  touch-action: manipulation;
}

select::-ms-expand {
  display: none;
}

h1, h2 {
    font-weight: normal;
    font-style: normal;
}

h1 {
  font-size: 26px;
}

h3 {
  font-size: 18px;
  font-weight: normal;
}

html, body, #root {
  height: 100%;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a, .link {
  color: #1A1A1A;
  text-decoration: underline;
  cursor: pointer;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/* prevent dotted outline in Firefox */
button::-moz-focus-inner {
  border: 0;
}

.center {
  text-align: center;
}

.widgetContent {
  min-width: 300px;
  padding: 20px 20px 20px 20px;
}

.widgetContent:first-child p {
  margin-top: 0;
}

.marginBottom {
  margin-bottom: 20px;
}

.marginBottomTen {
  margin-bottom: 10px;
}

.widgetContent div:last-child {
  margin-bottom: 0;
}

.invalidFlow {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.addressBox {
  display: inline-block;
}

.productTable {
  width: 100%;
  margin-bottom: 20px;
}

.alignRight {
  text-align: right;
}

.grey {
  color: #9B9B9B;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #c4c4c4;
  margin: 1em 0;
  padding: 0;
}

.centered {
  display: flex;
  justify-content: center;
}
