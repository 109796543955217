/* autoprefixer grid: autoplace */

.container, .containerLeft {
  height: 100%;
  display: grid;
  grid-template-rows: 250px auto 1fr 100px;
  grid-template-columns: 1fr 1fr 1fr 384px 1fr;
  grid-template-areas: "header header header header header" ". sidebar sidebar content ." ". . . . ." "footer footer footer footer footer";
}

.smallHeader {
  grid-template-rows: 60px auto 1fr 100px;
}

.containerLeft {
  grid-template-columns: 1fr 384px 1fr 1fr 1fr;
  grid-template-areas: "header header header header header" ".  content sidebar sidebar ." ". . . . ." "footer footer footer footer footer";
}

.header {
  grid-area: header;
}

.contentTopRight, .contentTopLeft {
  align-self: start;
  grid-area: content;
}

.contentBottomLeft, .contentBottomRight {
  align-self: end;
  grid-area: content;
}

.sidebar {
  grid-area: sidebar;
}

.sidebarTopRight, .sidebarTopLeft {
  align-self: start;
  margin-top: 5px;
}

.sidebarBottomLeft, .sideBarBottomRight {
  align-self: end;
}

.sidebarTopRight, .sideBarBottomRight {
  text-align: right;
}

.footer {
  grid-area: footer;
  display: grid;
  grid-template-columns: 1fr 384px 1fr 1fr 1fr;
  grid-template-areas: "blankLeft faq faq faq blankRight" "row2 row2 row2 row2 row2";
}

.faq {
  grid-area: faq;
}

.row2 {
  grid-area: row2;
}

.blankLeft {
  grid-area: blankLeft;
  margin-top: 2.5em;
  background: white;
}

.blankRight {
  grid-area: blankRight;
  margin-top: 2.5em;
  background: white;
}

/* Small Smartphone */
@media (max-width: 363px) {
  .container, .containerLeft {
    grid-template-rows: 100px 1fr auto 100px;
    grid-template-columns: auto;
    grid-template-areas: "header" "content" "sidebar" "footer";
  }

  .sidebar {
    margin-top: 20px;
  }

  .sidebarTopRight, .sidebarBottomLeft {
    text-align: left;
  }
}

/* Smartphone */
@media (min-width: 364px) and (max-width: 480px) {
  .container, .containerLeft {
    grid-template-rows: 100px 1fr auto 100px;
    grid-template-columns: 4px auto 4px;
    grid-template-areas: ". header ." ". content ." ". sidebar ." "footer footer footer";
  }

  .contentBottomLeft, .contentTopRight, .contentBottomRight, .contentTopLeft {
    align-self: start;
  }


  .footer {
    grid-template-columns: 20px auto;
    grid-template-areas: "blankLeft faq blankRight" "row2 row2 row2";
  }
}

/* iPad Portrait */
@media (min-width: 481px) and (max-width: 768px) {
  .container, .containerLeft {
    grid-template-rows: 150px 1fr auto 100px;
    grid-template-columns: 20px 384px 1fr;
    grid-template-areas: ". header header" ". content ." ". sidebar sidebar" "footer footer footer";
  }

  .smallHeader {
    grid-template-rows: 130px auto 1fr 100px;
  }

  .contentBottomLeft, .contentTopRight, .contentTopLeft, .contentBottomRight {
    align-self: end;
  }

  .footer {
    grid-template-columns: 20px auto 20px;
    grid-template-areas: "blankLeft faq blankRight" "row2 row2 row2";
  }

  .sidebarTopRight, .sidebarBottomLeft {
    text-align: left;
  }
}

/* iPad Landscape */
@media (min-width: 769px) and (max-width: 1024px) {
  .container, .containerLeft {
    grid-template-rows: 150px 1fr 1fr 100px;
    grid-template-columns: 20px auto 384px 20px;
    grid-template-areas: ". header header ." ". . . ." ". sidebar content ." "footer footer footer footer";
  }

  .smallHeader {
    grid-template-rows: 130px auto 1fr 100px;
  }

  .containerLeft {
    grid-template-columns: 20px 384px;
    grid-template-areas: ". header header" ". . ." ". content sidebar" "footer footer footer";
  }

  .footer {
    grid-template-columns: 20px auto 120px;
    grid-template-areas: "blankLeft faq blankRight" "row2 row2 row2";
  }
}

/* Large desktop */
@media (min-width: 1025px) {
  .header {
    margin-left: 20px;
  }


  .footer {
    grid-template-columns: 1fr 384px 1fr 1fr 1fr;
    grid-template-areas: "blankLeft faq faq blankRight blankRight" "row2 row2 row2 row2";
  }
}
